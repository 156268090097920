<template>
  <v-row flat class="pa-0 ma-0 transparent">
    <v-col cols="12" md="4">
      <v-row class="fill-height minheight">
        <v-col cols="4" class="d-flex align-center justify-center">
          <div v-if="accounts[1]">
            <v-avatar color="teal darken-4" size="130" class="border mt-5 ml-5">
              <v-img
                v-if="accounts[1].pictureId"
                :src="getImage(accounts[1].picture)"
                alt=""
              ></v-img>
              <span v-else class="white--text display-3">
                {{ accounts[1].name.substring(0, 1).toUpperCase() }}
              </span>
            </v-avatar>
            <h1 class="mt-3 ml-5 text-center">2nd</h1>
            <h4 class="ml-5 text-center text--secondary">
              {{
                tabtype == "topCaller"
                  ? `${accounts[1].count} `
                  : `${accounts[1].timeSpentOnCall} Min`
              }}
            </h4>
            <h4 class="ml-5 text-center text--secondary">
              {{ tabtype == "topCaller" ? `Calls` : `Activity` }}
            </h4>
          </div>
        </v-col>
        <v-col cols="4" class="d-flex align-center justify-center">
          <div class="mt-n15" v-if="accounts[0]">
            <div class="d-flex justify-center">
              <v-icon size="40" color="amber" class="">mdi-crown</v-icon>
            </div>
            <v-avatar
              color="teal darken-4"
              size="150"
              class="border bringfront"
            >
              <v-img
                v-if="accounts[0].pictureId"
                :src="getImage(accounts[0].picture)"
                alt=""
              ></v-img>
              <span v-else class="white--text display-3">
                {{ accounts[0].name.substring(0, 1).toUpperCase() }}
              </span>
            </v-avatar>
            <h1 class="mt-3 text-center">1st</h1>
            <h4 class="text-center text--secondary">
              {{
                tabtype == "topCaller"
                  ? `${accounts[0].count} `
                  : `${accounts[0].timeSpentOnCall} Min`
              }}
            </h4>
            <h4 class="ml-n1 text-center text--secondary">
              {{ tabtype == "topCaller" ? `Calls` : `Activity` }}
            </h4>
          </div>
        </v-col>
        <v-col cols="4" class="d-flex align-center justify-center">
          <div v-if="accounts[2]">
            <v-avatar
              color="teal darken-4"
              size="125"
              class="border ml-n5 mt-5"
            >
              <v-img
                v-if="accounts[2].pictureId"
                :src="getImage(accounts[2].picture)"
                alt=""
              ></v-img>
              <span v-else class="white--text display-3">
                {{ accounts[2].name.substring(0, 1).toUpperCase() }}
              </span>
            </v-avatar>
            <h1 class="mt-3 ml-n5 text-center">3rd</h1>
            <h4 class="ml-n5 text-center text--secondary">
              {{
                tabtype == "topCaller"
                  ? `${accounts[2].count} `
                  : `${accounts[2].timeSpentOnCall} Min`
              }}
            </h4>
            <h4 class="ml-n5 text-center text--secondary">
              {{ tabtype == "topCaller" ? `Calls` : `Activity` }}
            </h4>
          </div>
        </v-col>
      </v-row>
    </v-col>
    <v-col v-if="tabtype == 'topCaller'" cols="12" md="8" class="transparent">
      <v-card elevation="5" class="ma-md-5 pa-md-5 rounded">
        <v-data-table :headers="headersCaller" :items="accounts">
          <template v-slot:item.activity="{ item }">
            <h2>{{ item.activity }}</h2>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
    <v-col v-else cols="12" md="8" class="transparent ">
      <v-card elevation="5" class="ma-md-5 pa-md-5 rounded">
        <v-data-table :headers="headersActivity" :items="accounts">
          <template v-slot:item.activity="{ item }">
            <h2>{{ item.activity }}</h2>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
const _ = require("lodash");

export default {
  data() {
    return {
      headersCaller: [
        { text: "Name", value: "name", sortable: false },
        { text: "Role", value: "role", sortable: false },
        {
          text: "Calls",
          value: "count",
          align: "center",
          sortable: false,
        },
        {
          text: "Connected Calls",
          value: "connectedCalls",
          align: "center",
          sortable: false,
        },
        {
          text: "Not Connected Calls",
          value: "notConnectedCalls",
          align: "center",
          sortable: false,
        },
      ],
      headersActivity: [
        { text: "Name", value: "name", sortable: false },
        { text: "Role", value: "role", sortable: false },
        {
          text: "Active (Minutes)",
          value: "timeSpentOnCall",
          sortable: false,
        },
      ],
    };
  },
  props: ["tabtype", "accounts"],
  methods: {
    getImage(pic) {
      return `${this.$store.state.ENDPOINT}/storages/get/${pic}?access_token=${this.$store.getters.user.token}`;
    },
  },
};
</script>

<style scoped>
.bringfront {
  z-index: 1;
}
.v-application .teal.darken-4 {
  border: none;
}
.border {
  border: 5px solid #7fff00 !important;
  box-shadow: 0 0 20px #fbff00b0;
}
.crown {
  margin-top: -200px;
  transform: rotate(-20deg);
}
.minheight {
  min-height: 350px !important;
}
</style>
